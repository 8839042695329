<template>
  <div class="max-w-[90%] lg:max-w-[80%] m-auto flex flex-col lg:flex-row justify-between p-[30px] bg-[#262626] rounded-2xl gap-[30px]">
    <div v-for="contact in contactData" :key="contact.id" class="flex items-center lg:items-start justify-center flex-col gap-[5px] text-xl text-center">
      <template v-if="contact.link">
        <a :href="contact.link" class="font-normal">
          <div class="text-[#E54825] font-semibold">
            {{ contact.title }}
          </div>
          <div class="text-white break-words">
            {{ contact.value }}
          </div>
        </a>
      </template>
      <template v-else>
        <div class="text-[#E54825] font-semibold">
          {{ contact.title }}
        </div>
        <div class="text-white break-words">
          {{ contact.value }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { usePhoneNumber } from '@/composables/usePhoneNumber'

const phoneNumber = usePhoneNumber()

const contactData = [
  {
    id: 1,
    title: 'Call Us',
    value: phoneNumber,
    link: `tel:${phoneNumber}`
  },
  {
    id: 2,
    title: 'Email Us',
    value: 'info@gocanopyroofing.com',
    link: 'mailto:info@gocanopyroofing.com'
  },
  {
    id: 3,
    title: 'Our location',
    value: '1 Concourse Pkwy Suite 800 Sandy Springs, GA 30328'
  }
]
</script>
